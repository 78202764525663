@import url(https://fonts.googleapis.com/css?family=Space+Mono:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Space+Mono:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* loader */
.loader {
  border: 10px solid #f3f3f355;
  border-top: 10px solid #ff810b;
  margin: auto;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* scrollbar */ 
::-webkit-scrollbar {
  width: 8px;
  left: 8px;
}

::-webkit-scrollbar-track {
  background: #ff000000;
}

::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(95, 95, 95);
}

body {
  overflow: overlay;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: black;
}

.App {
  text-align: center;
}

#Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10vh;
  margin-left: 60vw;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  width: auto;
  padding: 2%;
  overflow-x: hidden;
}

.section {
  background-color: #ffffff;
  height: 80vh;
  color: black;
}

.sectionHeader {
  position: relative;
  width: 100%;
  color: white;
  text-align: center;
  justify-content: center;
  font-size: calc(20px + 3vmin);
  margin: 0;
  padding-top: 40px;
  padding-bottom: 5%;
  background-color: #ff800065;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
  width: 60%;
  height: 80%;
  padding: 20%;
}

.hero {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; 
}

.hero-img {
  top: 0px;
  position: fixed;
  max-height: 150%;
}

h1{
  font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-size: calc(10px + 5vmin);
  margin-bottom: 0%;
}

h6{
  font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 10%;
}

/* Down Arrow Flashing indicator */

.downArrow {
  color: white;
  width: 100%;
  padding: 15%;
  padding-top: 0%;
  text-align: center;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.downArrow > span{
  -webkit-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
}

.description{
  position: absolute;
}

.link {
  color: #61dafb;
  font-family: 'Playfair Display', 'Helvetica Neue', sans-serif;
  padding: 10px;
}

.pagination-icons{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  padding: 0.5em;
}

.menu {
  width: 6vw;
  color: white;
}

.menu-icon{
  color: white;
  position: fixed;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

/* menu icon -- credit to Jesse Couch https://codepen.io/designcouch/pen/ExvwPY with modifications */
#nav-icon {
  width: 45px;
  height: 30px;
  position: fixed;
  margin: 20px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 4;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 6px;
  width: 50%;
  background: #003F7D;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
  top: 10px;
}

#nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
  top: 20px;
}

#nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 5px;
}

#nav-icon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 5px;
}

#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon.open span:nth-child(5) {
  left: 5px;
  top: 20px;
}

#nav-icon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 20px;
}

.navbar.menu-opened{
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition-duration: 0.25s;
}

.menu-closed {
  -webkit-transform: translateX(-26vw);
          transform: translateX(-26vw);
  color:rgba(0, 0, 0, 0);
  transition-duration: 0.25s;
}

.navbar {
  color: black;
  background-color: rgba(255, 255, 255, 0.498);
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  z-index: 1;
}

@media only screen and (max-width: 670px) {
  .navbar{
    padding-top: 15%;
  }
}

.navlink {
  padding-top: 20%;
  position: relative;
  display: flex;
  color: black;
  font-size: calc(10px + 3vmin);
  text-decoration: none;
}

.navlink:hover {
  transition-duration: 0.2s;
  color: rgba(255, 255, 255, 0.533);
}

.socials{
  position: fixed;
  display: flex;
  bottom: 0px;
  right: 0px;
}

.socials > a > img{
  padding: 10px;
  height: calc(10px + 4vmin);
}

#backToTop{
  height: 5vh;
  width: 5vw;
  display: block;
  position: fixed;
}

footer > p{
  position: fixed;
  background-color: #003F7D;
  color: white;
}
.aboutSection{
    position: relative;
    height: 150vh;
    background-image: url(/static/media/steven3.66b253b8.jpg);
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    padding-top: 0;
}

.aboutSection > div{
    margin-top: -2.5%;
    padding-top: 10vh;
}

.aboutSection > div > :nth-child(odd) {
    margin: 5%;
    padding: 5%;
    margin-top: 0;
    width: 55%;
    font-size: calc(10px + 2vmin);
    color: black;
    background-color:rgba(215, 215, 215, 0.883);
    box-shadow: 10px 10px 8px #00000054;
}

.aboutSection > div > :nth-child(even) {
    margin: 5%;
    padding: 5%;
    margin-top: 0;
    width: 55%;
    margin-left: 35vw;
    font-size: calc(10px + 2vmin);
    color: black;
    background-color:rgba(215, 215, 215, 0.883);
    box-shadow: 10px 10px 8px #00000054;
}
/* Projects */
.projectsContainer {
    position: relative;
    height: 100vh;
    width: 100vw;
    margin: 0;
}

#projects-header {
    border: none;
}

.projectsContainer {
    display: flex;
    width: 100vw;
    align-content: center;
    text-align: center;
    overflow: hidden;
    background-color: #003e7d58;
    color: white;
    height: 70vh;
}

.project-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.title-container{
    width: 100%;
}

.project-image {
    display: inline-flex;
    max-width: 50%;
    max-height: 50%;
    padding-right: 5%;
    border-right: 1px solid white; 
}

.project-desc-card {
    display: inline-flex;
    flex-direction: column;
    padding: 5%;
    padding-left: 0;
    max-width: 50%;
}

.project-title{
    display: flex;
    position:absolute;
    justify-content: center;
    font-size: calc(20px + 1.6vmin);
    width: 100%;
}

.project-desc{
    width: 100%;
    padding: 10%;
    display: inline-flex;
    text-align: left;
    font-size: calc(8px + 1.6vmin);
}

.pagination {
    padding: 1%;
    background-color: #003e7da5;
    color: white;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.page-selector {
    font-size: 10em;
}

.page-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 13%;
    margin: auto;
    margin-left: 5%;
    cursor: pointer;
    width: 10vw;
    border-radius: 10%;
    z-index: 3;
}

.page-left:hover {
    transition-duration: 0.3s;
    color: white;
    background-color: #003F7D;
}

.page-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 13%;
    margin: auto;
    margin-right: 5%;
    cursor: pointer;
    width: 10vw;
    border-radius: 10%;
    z-index: 3;
}

.page-right:hover {
    transition-duration: 0.3s;
    color: white;
    background-color: #003F7D;
}

/* Project Mobile Rules */
@media only screen and (max-width: 670px) {
    .project-card {
    display: flex;
    padding-top: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    }

    .project-image {
    display: inline-flex;
    padding-top: 20%;
    max-width: 100%;
    max-height: 100%;
    padding-bottom: 10%;
    border-bottom: 1px solid white;
    }
    
    .project-desc-card {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding-top: 0;
    }
    
    .project-title{
    display: flex;
    text-decoration: underline;
    justify-content: center;
    font-size: calc(20px + 1.6vmin);
    width: 100%;
    }
    
    .project-desc{
    display: flex;
    text-align: left;
    font-size: calc(8px + 1.6vmin);
    }
}
#resume {
    height: 550vh;
}

.timeline-card {
    height: 100%;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.timeline {
    display: flex;
    border-left: 4px solid white;
    margin-left: 25vw;
}

.timeline-horiz {
    width: 10%;
    margin-left: -5px;
    border-bottom: 3px solid white;
}

.timeline-year {
    display: flex;
    position: absolute;
    left: calc(-12px - 2em);
    font-size: calc(6px + 1em)
}

.timeline-node {
    display: flex;
    align-items: center;
    margin-left: -14px;
    color: white;
    padding-top: 10vh;
    padding-bottom: 50%;
}

.timeline-node-title {
    display: inline-flex;
    font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
    font-size: calc(10px + 1em);
    border-bottom: 1px solid white;
}

.timeline-job-details {
    padding-left: 5px;
}

.timeline-node-company {
    font-size: calc(12px + 1em);
    font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
}

.timeline-node-desc {
    padding-top: 3%;
    padding-left: 10%;
    max-width: 80%;
    font-size: calc(0px + 1em);
}

.timeline-node-skills {
    display: flex;
    align-items: center;
}

.timeline-subtitle {
    padding: 1%;
}

.skill-image {
    display: inline-flex;
    max-height: calc(10px + 1em);
    max-width: calc(10px + 1em);
    margin-left: 10px;
    margin-top: 10px;
    align-content: center;
}
