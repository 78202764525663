/* Projects */
.projectsContainer {
    position: relative;
    height: 100vh;
    width: 100vw;
    margin: 0;
}

#projects-header {
    border: none;
}

.projectsContainer {
    display: flex;
    width: 100vw;
    align-content: center;
    text-align: center;
    overflow: hidden;
    background-color: #003e7d58;
    color: white;
    height: 70vh;
}

.project-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.title-container{
    width: 100%;
}

.project-image {
    display: inline-flex;
    max-width: 50%;
    max-height: 50%;
    padding-right: 5%;
    border-right: 1px solid white; 
}

.project-desc-card {
    display: inline-flex;
    flex-direction: column;
    padding: 5%;
    padding-left: 0;
    max-width: 50%;
}

.project-title{
    display: flex;
    position:absolute;
    justify-content: center;
    font-size: calc(20px + 1.6vmin);
    width: 100%;
}

.project-desc{
    width: 100%;
    padding: 10%;
    display: inline-flex;
    text-align: left;
    font-size: calc(8px + 1.6vmin);
}

.pagination {
    padding: 1%;
    background-color: #003e7da5;
    color: white;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.page-selector {
    font-size: 10em;
}

.page-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 13%;
    margin: auto;
    margin-left: 5%;
    cursor: pointer;
    width: 10vw;
    border-radius: 10%;
    z-index: 3;
}

.page-left:hover {
    transition-duration: 0.3s;
    color: white;
    background-color: #003F7D;
}

.page-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 13%;
    margin: auto;
    margin-right: 5%;
    cursor: pointer;
    width: 10vw;
    border-radius: 10%;
    z-index: 3;
}

.page-right:hover {
    transition-duration: 0.3s;
    color: white;
    background-color: #003F7D;
}

/* Project Mobile Rules */
@media only screen and (max-width: 670px) {
    .project-card {
    display: flex;
    padding-top: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    }

    .project-image {
    display: inline-flex;
    padding-top: 20%;
    max-width: 100%;
    max-height: 100%;
    padding-bottom: 10%;
    border-bottom: 1px solid white;
    }
    
    .project-desc-card {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding-top: 0;
    }
    
    .project-title{
    display: flex;
    text-decoration: underline;
    justify-content: center;
    font-size: calc(20px + 1.6vmin);
    width: 100%;
    }
    
    .project-desc{
    display: flex;
    text-align: left;
    font-size: calc(8px + 1.6vmin);
    }
}