@import url('https://fonts.googleapis.com/css?family=Space+Mono:regular,bold,italic&subset=latin,latin-ext');

/* loader */
.loader {
  border: 10px solid #f3f3f355;
  border-top: 10px solid #ff810b;
  margin: auto;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* scrollbar */ 
::-webkit-scrollbar {
  width: 8px;
  left: 8px;
}

::-webkit-scrollbar-track {
  background: #ff000000;
}

::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(95, 95, 95);
}

body {
  overflow: overlay;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: black;
}

.App {
  text-align: center;
}

#Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10vh;
  margin-left: 60vw;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  width: auto;
  padding: 2%;
  overflow-x: hidden;
}

.section {
  background-color: #ffffff;
  height: 80vh;
  color: black;
}

.sectionHeader {
  position: relative;
  width: 100%;
  color: white;
  text-align: center;
  justify-content: center;
  font-size: calc(20px + 3vmin);
  margin: 0;
  padding-top: 40px;
  padding-bottom: 5%;
  background-color: #ff800065;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
  width: 60%;
  height: 80%;
  padding: 20%;
}

.hero {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; 
}

.hero-img {
  top: 0px;
  position: fixed;
  max-height: 150%;
}

h1{
  font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-size: calc(10px + 5vmin);
  margin-bottom: 0%;
}

h6{
  font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 10%;
}

/* Down Arrow Flashing indicator */

.downArrow {
  color: white;
  width: 100%;
  padding: 15%;
  padding-top: 0%;
  text-align: center;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.downArrow > span{
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
}

.description{
  position: absolute;
}

.link {
  color: #61dafb;
  font-family: 'Playfair Display', 'Helvetica Neue', sans-serif;
  padding: 10px;
}

.pagination-icons{
  transform: scale(1.2);
  padding: 0.5em;
}

.menu {
  width: 6vw;
  color: white;
}

.menu-icon{
  color: white;
  position: fixed;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

/* menu icon -- credit to Jesse Couch https://codepen.io/designcouch/pen/ExvwPY with modifications */
#nav-icon {
  width: 45px;
  height: 30px;
  position: fixed;
  margin: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 4;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 6px;
  width: 50%;
  background: #003F7D;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
  top: 10px;
}

#nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
  top: 20px;
}

#nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 5px;
}

#nav-icon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 5px;
}

#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon.open span:nth-child(5) {
  left: 5px;
  top: 20px;
}

#nav-icon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 20px;
}

.navbar.menu-opened{
  transform: translateX(0);
  transition-duration: 0.25s;
}

.menu-closed {
  transform: translateX(-26vw);
  color:rgba(0, 0, 0, 0);
  transition-duration: 0.25s;
}

.navbar {
  color: black;
  background-color: rgba(255, 255, 255, 0.498);
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  z-index: 1;
}

@media only screen and (max-width: 670px) {
  .navbar{
    padding-top: 15%;
  }
}

.navlink {
  padding-top: 20%;
  position: relative;
  display: flex;
  color: black;
  font-size: calc(10px + 3vmin);
  text-decoration: none;
}

.navlink:hover {
  transition-duration: 0.2s;
  color: rgba(255, 255, 255, 0.533);
}

.socials{
  position: fixed;
  display: flex;
  bottom: 0px;
  right: 0px;
}

.socials > a > img{
  padding: 10px;
  height: calc(10px + 4vmin);
}

#backToTop{
  height: 5vh;
  width: 5vw;
  display: block;
  position: fixed;
}

footer > p{
  position: fixed;
  background-color: #003F7D;
  color: white;
}