@import url('https://fonts.googleapis.com/css?family=Space+Mono:regular,bold,italic&subset=latin,latin-ext');

#resume {
    height: 550vh;
}

.timeline-card {
    height: 100%;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.timeline {
    display: flex;
    border-left: 4px solid white;
    margin-left: 25vw;
}

.timeline-horiz {
    width: 10%;
    margin-left: -5px;
    border-bottom: 3px solid white;
}

.timeline-year {
    display: flex;
    position: absolute;
    left: calc(-12px - 2em);
    font-size: calc(6px + 1em)
}

.timeline-node {
    display: flex;
    align-items: center;
    margin-left: -14px;
    color: white;
    padding-top: 10vh;
    padding-bottom: 50%;
}

.timeline-node-title {
    display: inline-flex;
    font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
    font-size: calc(10px + 1em);
    border-bottom: 1px solid white;
}

.timeline-job-details {
    padding-left: 5px;
}

.timeline-node-company {
    font-size: calc(12px + 1em);
    font-family: 'Space Mono', 'Helvetica Neue', sans-serif;
}

.timeline-node-desc {
    padding-top: 3%;
    padding-left: 10%;
    max-width: 80%;
    font-size: calc(0px + 1em);
}

.timeline-node-skills {
    display: flex;
    align-items: center;
}

.timeline-subtitle {
    padding: 1%;
}

.skill-image {
    display: inline-flex;
    max-height: calc(10px + 1em);
    max-width: calc(10px + 1em);
    margin-left: 10px;
    margin-top: 10px;
    align-content: center;
}