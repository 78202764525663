.aboutSection{
    position: relative;
    height: 150vh;
    background-image: url('../../images/steven3.jpg');
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    padding-top: 0;
}

.aboutSection > div{
    margin-top: -2.5%;
    padding-top: 10vh;
}

.aboutSection > div > :nth-child(odd) {
    margin: 5%;
    padding: 5%;
    margin-top: 0;
    width: 55%;
    font-size: calc(10px + 2vmin);
    color: black;
    background-color:rgba(215, 215, 215, 0.883);
    box-shadow: 10px 10px 8px #00000054;
}

.aboutSection > div > :nth-child(even) {
    margin: 5%;
    padding: 5%;
    margin-top: 0;
    width: 55%;
    margin-left: 35vw;
    font-size: calc(10px + 2vmin);
    color: black;
    background-color:rgba(215, 215, 215, 0.883);
    box-shadow: 10px 10px 8px #00000054;
}